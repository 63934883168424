<template>
  <div class="GalleryUploadContent">
    <input class="GalleryUploadWall" type="file" :accept="accept" :multiple="'multiple'" @change="appendFiles" :id="`in${id}`" />
    <div class="GalleryUploadList">
      <GalleryFileUploaderGroup v-for="(file, index) in Go.arrayReverse(preview)" :item="file" :key="index" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal"],
  components: {
    GalleryFileUploaderGroup: () => import("./GalleryFileUploaderGroup.vue"),
  },
  data: function() {
    return {
      id: Go.uuid(),
      items: [],
      files: [],
      preview: [],
      accept: "image/png, image/jpeg, image/jpg",
    };
  },
  methods: {
    appendFiles: async function(e) {
      this.inFiles = e.target.files;

      if (!this.inFiles.length) {
        return;
      }

      for (const file of this.inFiles) {
        if (file.size) {
          const item = { name: file.name, type: file.type, src: URL.createObjectURL(file), id: Go.uuid(), file };
          this.files.push(file);
          this.items.push(item);
        }
      }

      this.formatFiles();
    },
    formatFiles: function() {
      this.preview = Go.arrayRemoveDuplicates(this.preview);
      this.preview = Go.arrayGroupOf(3, this.items, { id: "$id", items: [], size: 309 });
    },
    init: async function() {
      this.toolbar = await this.view({
        header: false,
        noclose: true,
        class: "menu bottom stay addon",
        component: () => import("./GalleryUploadOptions.vue"),
        parentId: this.id,
      });

      this.modal.onClose = () => {
        this.toolbar.close();
        this.$store.state.feedKey++;
      };
    },
  },
  mounted: function() {
    this.init();
  },
};
</script>

<style lang="scss">
.GalleryUploadContent {
  @include Absolute();

  *:not(.GalleryUploadWall) {
    pointer-events: none;
  }

  .GalleryUploadWall {
    @include Absolute();
    z-index: 0;
    background-color: rgba($color: #000000, $alpha: 0);
  }

  .GalleryUploadList {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    padding: var(--header-height) $mpadding/2 calc(var(--header-height) + calc(#{$mpadding} * 3)) $mpadding/2;
    z-index: 1;
  }

  .GalleryFile * {
    pointer-events: all;
  }
}
</style>
